.modal-header {
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
}

.modal-body {
  padding: 30px 40px;
}

.modal-footer {
  border: none;
}

.delete-form {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  .form-control {
    font-size: 20px;
  }
}

.item-modal-buttons {
  margin-top: 10px;
  margin-bottom: 15px;
}

.standard-modal {
  padding: 20px;
  @include MQ(S) {
    padding: 40px 80px;
  }
}
