.sidebar {
  background: $brand-muted-dark;
  color: #fff;
}

.sidebar-header {
  padding: 5px 30px;
  @include MQ(S) {
    padding: 20px 30px;
  }
  .logo {
    margin-left: -6px;
    @include MQ(Sneg) {
      img {
        width: 110px;
      }
    }
  }
  .close-toggle {
    color: #fff;
    opacity: 1;
    font-size: 30px;
    position: absolute;
    top: 5px;
    right: 18px;
    &:hover {
      cursor: pointer;
    }
  }
}

.sidebar-nav {
  padding: 5px 30px;
  @include MQ(S) {
    padding: 20px 30px;
  }
  .nav-title {
    text-transform: uppercase;
    border-bottom: 1px solid $white-o5;
    padding-bottom: 3px;
    margin-bottom: 10px;
    color: $white-o5;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    display: flex;
  }
  .nav-btn {
    margin-left: auto;
  }
  a {
    font-size: 20px;
    display: block;
    color: #fff;
    padding: 5px 0 5px 30px;
    position: relative;
    i {
      color: rgba(255,255,255,0.3);
      position: absolute;
      left: 0;
      top: 9px
    }
    &:hover, &:focus, &.active {
      text-decoration: none;
      color: $yellow;
      cursor: pointer;
      i {
        color: $yellow;
      }
    }
    .subtext {
      display: block;
      font-size: 0.7em;
    }
  }
}

.logout-mobile {
  .btn {
    text-align: left;
  }
  .fa {
    width: 1.28571em;
    text-align: center;
  }
  @include MQ(S) {
    display: none !important;
  }
}
