.region-tags {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
  .tags-group {
    background: #f5f5f5;
    margin-bottom: 30px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #ccc;
    // box-shadow: 1px 1px 5px rgba(0,0,0,0.1);
  }
  .line-item {
    padding: 10px 20px;
    display: flex;
    width: 100%;
    .badge {
      margin-left: auto;
    }
    &.category {
      &:hover {
        cursor: pointer;
        background: #ddd;
        .fa {
          color: #666;
        }
      }
      .form-control {
        background: transparent;
        border: none;
        color: $text-color;
        padding: 0;
        height: auto;
      }
    }
  }

  .edit-link {
    margin-left: auto;
    opacity: 0;
    a {
      // color: rgba(0,0,0,0.3);
    }
  }
  .type {
    font-size: 20px;
    background: #8ab580;
    background: darkslategray;
    color: #fff;
    border-bottom: 1px solid #fff;
    .edit-link {
      font-size: 14px;
      a {
        color: rgba(255,255,255,0.5);
      }
    }
    .form-control {
      background: transparent;
      font-size: 20px;
      border: none;
      color: #fff;
      padding: 0;
      height: auto;
    }
  }
}