
.h-span-container {
  width: 600px;
  height: 50px;
  background-color: #FFF;
  -webkit-box-shadow: 0 0 2px 0 #dbdbdb;
     -moz-box-shadow: 0 0 2px 0 #dbdbdb;
          box-shadow: 0 0 2px 0 #dbdbdb;
  margin-bottom: 20px;
}

.v-span-container {
  float: left;
  margin-right: 20px;
  width: 50px;
  height: 400px;
  background-color: #FFF;
  -webkit-box-shadow: 0 0 2px 0 #dbdbdb;
     -moz-box-shadow: 0 0 2px 0 #dbdbdb;
          box-shadow: 0 0 2px 0 #dbdbdb;
  margin-bottom: 20px;
}

span.h-base {
  background-color: #272c2e;
  width: 520px;
  height: 50px;
  position: absolute;
}

span.v-base {
  background-color: #272c2e;
  width: 50px;
  height: 350px;
  position: absolute;
}

.v-weight {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
}

.weight {
  float: right;
  height: 50px;
  width: 80px;
  line-height: 50px;
  font-size: 20px;
  text-align: center;
}

span.h-progress {
  position: absolute;
  background-color: #bcdd63;
}

span.v-progress {
  position: absolute;
  bottom: 0;
  background-color: #ff1919;
}

.animation {
  -webkit-transition-duration: 0.3s;
     -moz-transition-duration: 0.3s;
      -ms-transition-duration: 0.3s;
       -o-transition-duration: 0.3s;
          transition-duration: 0.3s;
}