.line-item-checkbox {
  .checkbox {
    padding-left: 20px;
  }

  .checkbox label {
    display: inline-block;
    position: relative;
    padding-left: 5px;
  }

  .checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  }

  .checkbox label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    line-height: 16px;
    left: 0;
    top: 0;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 12px;
    color: #555555;
  }

  .checkbox input[type="checkbox"] {
    opacity: 0;
    z-index: 1;
  }

  .checkbox input[type="checkbox"]:focus + label::before {
    // outline: thin dotted;
    // outline: 5px auto -webkit-focus-ring-color;
    // outline-offset: -2px;
    border-color: #666;
  }

  .checkbox input[type="checkbox"]:checked + label::after {
    font-family: 'FontAwesome';
    content: "\f00c";
  }

  .checkbox input[type="checkbox"]:disabled + label {
    opacity: 0.65;
  }

  .checkbox input[type="checkbox"]:disabled + label::before {
    background-color: #eeeeee;
    cursor: not-allowed;
  }

  .checkbox.checkbox-circle label::before {
    border-radius: 50%;
  }

  .checkbox.checkbox-inline {
    margin-top: 0;
  }

  .checkbox.checkbox-single label {
    height: 17px;
  }

  .checkbox-primary input[type="checkbox"]:checked + label::before {
    background-color: $blue-bright;
    border-color: $blue-bright;
  }

  .checkbox-primary input[type="checkbox"]:checked + label::after {
    color: #fff;
  }

  .checkbox-danger input[type="checkbox"]:checked + label::before {
    background-color: #d9534f;
    border-color: #d9534f;
  }

  .checkbox-danger input[type="checkbox"]:checked + label::after {
    color: #fff;
  }

  .checkbox-info input[type="checkbox"]:checked + label::before {
    background-color: #5bc0de;
    border-color: #5bc0de;
  }

  .checkbox-info input[type="checkbox"]:checked + label::after {
    color: #fff;
  }

  .checkbox-warning input[type="checkbox"]:checked + label::before {
    background-color: #f0ad4e;
    border-color: #f0ad4e;
  }

  .checkbox-warning input[type="checkbox"]:checked + label::after {
    color: #fff;
  }

  .checkbox-success input[type="checkbox"]:checked + label::before {
    background-color: #5cb85c;
    border-color: #5cb85c;
  }

  .checkbox-success input[type="checkbox"]:checked + label::after {
    color: #fff;
  }
}
