.search-wrapper {
  width: 100%;
  .form-control {
    border: none;
    border-radius: 0;
    font-style: italic;
    font-size: 18px;
    padding: 17px 20px 12px 15px;
    border-bottom: 2px solid transparent;
    box-shadow: none;
    height: 49px;
    &:focus {
      // border-bottom-color: $blue;
      outline: none;
      box-shadow: none;
      background-color: #f5f5f5;
    }
  }
  .dropdown-menu {
    width: 100%;
    border-radius: 0;
    padding: 0;
    margin: 0;
    border: $border-light;
    @include MQ(XS) {
      width: calc(100% - 160px);
    }
    > li {
      > a {
        padding: 15px 20px;
      }
      &:not(:last-child) {
        a {
          border-bottom: $border-light;
        }
      }
    }
  }
  .dropdown-menu > .active > a,
  .dropdown-menu > .active > a:hover,
  .dropdown-menu > .active > a:focus {
    background-color: #f7f9fa;
    color: $text-color;
  }
}

.search-wrapper {
  // display: flex;
  // padding-left: 15px;
  // padding-top: 5px;
  width: 100%;
  color: #555;
  .form-control {
    border: none;
    border-radius: 0;
    font-style: italic;
    font-size: 18px;
    padding: 17px 20px 12px 15px;
    border-bottom: 2px solid transparent;
    box-shadow: none;
    height: 49px;
    &:focus {
      // border-bottom-color: $blue;
      outline: none;
      box-shadow: none;
      background-color: #f5f5f5;
    }
  }
  label {
    display: none;
  }
  .associations {
    text-transform: uppercase;
    font-size: 11px;
    color: #999;
    padding-left: 8px;
  }
  strong {
    font-weight: normal;
  }
  .dropdown-menu {
    width: 100%;
    border-radius: 0;
    padding: 0;
    margin: 0;
    border: $border-light;
    @include MQ(XS) {
      width: calc(100% - 160px);
    }
    > li {
      > a {
        padding: 15px 20px;
      }
      &:not(:last-child) {
        a {
          border-bottom: $border-light;
        }
      }
    }
  }
  .dropdown-menu > .active > a,
  .dropdown-menu > .active > a:hover,
  .dropdown-menu > .active > a:focus {
    background-color: #f7f9fa;
    color: $text-color;
  }
}

.account-icon {
  &:before {
    content: '\f07c';
  }
}

.contact-icon {
  &:before {
    content: '\f007';
  }
}

.project-icon {
  &:before {
    content: '\f0b1'
  }
}

.no-result-wrapper {
  .dropdown-menu {
    box-shadow: none;
    width: 100%;
    border: none;
  }
}

.no-results {
  padding: 15px 20px;
  background: #fff;
  border: $border-light;
}


