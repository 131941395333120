.config-basic {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
}

.config-row {
  display: flex;
  align-items: center;
  font-size: 20px;
  background: #ddd;
  margin-bottom: 5px;
  .save-btn {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.config-row,
.config-add-form {
  .form-control {
    background: none;
    font-size: 20px;
    padding: 10px 20px;
    height: auto;
    border-bottom: none;
    &:focus {
      background: #ccc;
    }
  }
}
