.stock-tags {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
  .tags-group {
    background: #f5f5f5;
    margin-bottom: 30px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #ccc;
    // box-shadow: 1px 1px 5px rgba(0,0,0,0.1);
  }
  .line-item {
    padding: 10px 20px;
    display: flex;
    width: 100%;
    .badge {
      margin-left: auto;
    }
    &.category {
      &:hover {
        cursor: pointer;
        background: #ddd;
        .fa {
          color: #666;
        }
      }
      .form-control {
        background: transparent;
        border: none;
        color: $text-color;
        padding: 0;
        height: auto;
      }
    }
  }
  .drag-handle {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }
  .edit-link {
    margin-left: auto;
    opacity: 0;
    a {
      // color: rgba(0,0,0,0.3);
    }
  }
  .type {
    font-size: 20px;
    background: #8ab580;
    background: darkslategray;
    color: #fff;
    border-bottom: 1px solid #fff;
    .edit-link {
      font-size: 14px;
      a {
        color: rgba(255,255,255,0.5);
      }
    }
    .form-control {
      background: transparent;
      font-size: 20px;
      border: none;
      color: #fff;
      padding: 0;
      height: auto;
    }
  }
  .badge {
    background: #8ab580;
    background: darkslategray;
  }
  .btn-info {
    // color: #8ab580;
  }
}

.categories {
  // display: flex;
  &:hover {
    .drag-handle {
      opacity: 1;
    }
  }
}

.type,
.category {
  &:hover {
    .edit-link {
      opacity: 1;
    }
  }
}

.badge-wrapper {
  min-width: 45px;
  text-align: right;
}

.add-tags-wrapper {
  background: #eee;
  padding: 5px 0;
  margin: 0;
  border-bottom: 1px solid #ccc;
}

.add-tags-row {
  display: flex;
  align-items: center;
  padding: 2px 20px 2px 20px;
  .save-btn {
    padding-top: 8px;
  }
  .btn-pill {
    color: #666;
    &:hover, &:focus {
      background: darkslategray;
      color: #fff;
    }
  }
}

.add-tags-form {
  display: flex;
  flex-grow: 2;
  .form-control {
    margin-right: 5px;
    padding-left: 5px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 2px;
    padding: 10px 12px;
    height: auto;
  }
  .tag-description {
    flex-grow: 2;
  }
}

.btn-add-tag {
  margin: 5px 0 5px 15px;
}

.btn-add-category {
  margin-left: -5px;
}

.btn-add-tag,
.btn-add-category,
.btn-add-stock-type {
  color: $green;
  text-transform: uppercase;
  &:hover, &:focus {
    background: $green !important;
    color: #fff;
  }
}

.btn-set-pricing {
  color: $green;
  text-transform: uppercase;
  &:hover, &:focus {
    color: #fff;
    background: $green !important;
  }
}

.pricing-table-scroll {
  overflow:scroll;
  flex-grow:1;
  width:100%;
  display:flex;
  max-height: 650px;
}
