.project-types {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
  .project-type-group {
    background: #f5f5f5;
    margin-bottom: 30px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #ccc;
  }
  .project-type {
    display: flex;
    width: 100%;
    font-size: 20px;
    background: #8ab580;
    background: darkslategray;
    color: #fff;
    // border-bottom: 1px solid #fff;
    padding: 10px 20px;
    display: flex;
    .form-control {
      background: transparent;
      font-size: 20px;
      border: none;
      color: #fff;
      padding: 0;
      height: auto;
    }
  }
  .edit-link {
    margin-left: auto;
    opacity: 0;
    a {
      color: rgba(255,255,255,0.5);
      // color: rgba(0,0,0,0.3);
    }
  }
  .line-item {
    padding: 10px 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    cursor: pointer;
  }
}

.project-type {
  &:hover {
    .edit-link {
      opacity: 1;
    }
  }
}

.project-type {
  i {
    transition: transform 300ms ease;
    margin-right: 5px;
    transform: rotate(90deg);
    &.fa-pencil {
      transform: none;
    }
  }
}

.project-type.collapsed {
  i {
    transform: rotate(0deg);
  }
}

.project-type-title {
  &:hover {
    cursor: pointer;
    color: $yellow;
  }
}

.edit-project-type-name {
  width: 100%;
  display: flex;
}

.field-terms-and-conditions {
  // padding: 10px 20px;
  // border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  textarea {
    padding: 10px 12px;
    border: 1px solid #ddd;
    // width: 70%;
  }
  .save-btn {
    margin-left: 5px;
    padding-top: 8px;
  }
}

.btn-add-project-type {
  color: #67c951;
  text-transform: uppercase;
}
