.auth-login {
  background: $brand-muted-dark;
  .alert {
    background: rgba(0,0,0,0.5);
    color: rgba(255,255,255,0.5);
    border: none;
    font-size: 12px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      border-top: 1px solid rgba(255,255,255,0.1);
      padding-top: 7px;
      margin-top: 8px;
      color: #fff;
      font-size: 16px;
    }
  }
}

.app-login-wrapper {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  padding-left: 15px;
  padding-right: 15px;
  @include MQ(S) {
    padding-top: 100px;
  }
}

.login-header {
  text-align: center;
  margin-bottom: 10px;
}

.forgot-login {
  text-align: center;
  padding: 10px 0;
}