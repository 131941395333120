.details {
  .form-group {
    display: flex;
    margin-right: 0;
    &.service-run-details {
	    margin: 25px 0 0 0;
	    padding: 20px 30px 40px;
	    background: $light-gray;
	    border: $border-light;
	    flex-direction: column;
	    align-items: flex-start;
	    label {
		    font-weight: 600;
		    margin-bottom: 7px;
		    display: block;
	    }
	    .details-wrapper {
		    padding: 5px 15px;
		    background: #fff;
		    border: $border-light;
	    }
    }
  }
  .control-label {
    text-align: left;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 11px;
    padding-left: 15px;
    padding-top: 11px;
    width: 100px;
    @include MQ(Sneg) {
      padding-right: 5px;
    }
    @include MQ(S) {
      width: 125px;
    }
  }
  .input-wrapper {
    flex-grow: 2;
  }
  .form-control {
    border: none;
    border-bottom: 1px solid #ccc;
    box-shadow: none;
    padding-left: 0;
    font-weight: 500;
    border-radius: 0;
    &:focus {
      border-bottom-color: $blue-bright;
    }
    &[disabled] {
      border: none;
      background: none;
    }
  }
  textarea.form-control {
    border: none;
    resize: none;
  }
  .col-left {
    @include MQ(Sneg) {
      margin-bottom: 30px;
      padding-bottom: 20px;
      border-bottom: $border-light;
    }
    @include MQ(S) {
      padding-right: 30px;
    }
  }
  .col-right {
    @include MQ(Sneg) {
      margin-bottom: 30px;
      padding-bottom: 20px;
      border-bottom: $border-light;
    }
    @include MQ(S) {
      padding-left: 30px;
    }
  }
}

.form-group-checkbox {
  .control-label {
    width: auto;
  }
  .input-wrapper {
    padding-left: 10px;
    .form-control {
      margin-top: 2px;
    }
  }
}

.special-servicing-group {
  background: #f7f9fa;
  width: 100%;
  margin-left: 15px;
  border: 1px solid #eee;
  padding: 20px;
  margin-top: -5px;
  textarea.form-control {
    padding: 6px 10px;
  }
  .input-wrapper + .input-wrapper {
    margin-top: 6px;
  }
}

a.view-work-order {
  margin-left: 15px;
  margin-bottom: 10px;
}

// .account .section-content {
//   &[data-eq-state$="small"] {
//     .details-column {
//       @include make-sm-column(6);
//     }
//   }

//   &[data-eq-state$="medium"] {
//     .details-column {
//       @include make-sm-column(6);
//     }
//   }

//   &[data-eq-state$="large"] {
//     .details-column {
//       @include make-sm-column(6);
//     }.
//   }
// }
.detail-header {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  @include MQ(S) {
    margin-bottom: 4px;
  }
  @include MQ(M) {
    display: inline-block;
    margin-bottom: 20px;
  }
}

.project-zone-select .ui-select-bootstrap .ui-select-choices-row > a {
  margin-left: 25px;
}

.account-zone-helper-link {
  padding-left: 125px;
  padding-bottom: 15px;
}

.copy-link {
  display: inline-block;
  @include MQ(Mneg) {
    margin-left: -12px;
  }
}

// .form-actions {
//   display: flex;
//   border: 1px solid #ddd;
//   padding: 10px 15px;
//   margin-top: 15px;
//   border-radius: 2px;
//   align-items: center;
//   // background: #f5f5f5;
//   .button-label {
//     margin-right: 5px;
//   }
// }

// UI Select Overrides

// .details {
//   .ui-select-toggle {
//     border-bottom: 1px solid transparent;
//   }

//   .ui-select-container {
//     .btn-default {
//       &:hover {
//         background: none;
//       }
//     }
//   }
// }

/* Helper class to show styles when focus */
// .btn-default-focus {
//   outline: none;
//   border: none;
//   border-bottom: 1px solid $blue-bright;
//   background: none;
//   box-shadow: none;
//   .form-control {
//     border: none;
//   }
// }
