.run-list {
  flex: auto;
  // margin-left: 1px;
  // margin-right: 1px;
  width: percentage(1/7);
  min-width: 200px;
  min-height: 100px;
  padding: 5px;
  position: relative;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  // overflow: hidden;
  .new-run {
    background: $blue-bright;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    // transform: translate3D(0,25px,0);
    transition: opacity 500ms ease;
    opacity: 0;
    .btn-icon:hover {
      color: #fff;
      background: draken($blue-bright, 5%);
    }
    button {
      width: 100%;
    }
    i {
      transition: transform 300ms ease;
    }
  }
  &:hover {
    .new-run {
      opacity: 1;
      // transform: none;
    }
  }
  padding-bottom: 30px;
}

.new-run.open {
  opacity: 1;
  i {
    transform: rotate(45deg);
  }
}
