.truck-schedule {
  background: #f5f5f5;
  padding: 15px 10px 20px;
  @include MQ(S) {
    padding: 100px 30px 60px;
  }
}

.truck-schedule-wrapper {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.assignee-run {
  margin-bottom: 10px;
  @include MQ(S) {
    margin-bottom: 30px;
  }
}

.run-title {
  span {
    font-weight: normal;
    color: #ccc;
  }
}

.assignee-runHeader {
  background: #333;
  border-bottom: 1px solid #999;
  color: #fff;
  padding: 10px 20px;
  margin-bottom: 15px;
  border-radius: 4px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
}

.assignee-workOrder {
  background: #fff;
  border: 1px solid #ddd;
  border-bottom: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  &.actioned {
    background: #eee;
    opacity: 0.8;
    .btn-complete{
      background: $blue-bright !important;
      color: #fff;
    }
  }
}


.assignee-stats {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.assignee-stat {
  padding: 0 5px;
  .fa {
    opacity: 0.3;
  }
}

// Project

.project-details {
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  @include MQ(S) {
    display: flex;
  }
  a {
    color: $blue-bright;
  }
}

.project-header {
  // display: flex;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  padding: 10px 20px;
  margin-bottom: 20px;
  @include MQ(S) {
    display: flex;
  }
}

.project-detail {
  font-size: 20px;
  color: #666;
  @include MQ(S) {
    padding: 0 15px;
  }
  @include MQ(Sneg) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.truck-timing-instructions {
  padding-left: 5px;
  font-size: 16px;
  font-weight: normal;
  @include MQ(Sneg) {
    display: block;
  }
  // color: #999;
  .fa {
    color: $brand-danger;
    margin-right: 5px;
  }
}

.google-map-link {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  color: $blue-bright;
}

.project-col {
  flex: auto;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 4px;
}

.assignee-actions-col {
  background: none;
  border-color: transparent;
  @include MQ(S) {
    max-width: 230px;
  }
}

.assignee-actions {
  .btn {
    font-weight: bold;
    background: #ddd;
    text-transform: uppercase;
    font-size: 12px;
  }
}

.detail-title {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  padding-bottom: 3px;
  color: #333;
}