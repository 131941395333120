.input-wrapper {
  position: relative;
  .validation {
    border: 1px solid #e90b00;
    padding: 4px 10px;
    margin-top: 10px;
    background: #ffc0cb;
    &.ng-inactive {
      display: none;
    }
  }
}

.form-control-minimal {
  border: none;
  border-bottom: 1px solid #ccc;
  box-shadow: none;
  padding-left: 0;
  font-weight: 500;
  border-radius: 0;
  &:focus {
    border-bottom-color: $blue-bright;
    outline: none;
    box-shadow: none;
  }
  &[disabled] {
    border: none;
    background: none;
  }
}

.image-cropper {
  width: 80px;
  height: 80px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.uploadable-image {
  img {
    width: 100%;
    height: 100%;
  }
}

.profile-picture {
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
}

.uploadable-image {
  padding-bottom: 10px;
  label {
    display: block;
    padding-bottom: 8px;
  }
  .profile-image,
  .remove-icon {
    display: inline-block;
    vertical-align: middle;
  }
}

.ui-select-bootstrap {
  .btn {
    background: #f5f5f5;
    border: 1px solid #ddd;
    padding-left: 10px;
    border-radius: 0;
    font-weight: 500;
    box-shadow: none;
    &:active {
      top: 0;
    }
    &:focus {
      border-color: $blue-bright !important;
      outline: none;
      box-shadow: none;
    }
  }
  .ui-select-toggle {
    > .caret {
      border: none;
      font-style: normal;
      height: 100%;
      background: #eee;
      border-left: 1px solid #ddd;
      text-align: center;
      right: 0;
      top: 2px;
      width: 30px;
      &:before {
        font-family: "FontAwesome";
        content: '\f078';
        line-height: 30px;
      }
    }
  }
  .form-control {
    background: #f5f5f5;
    border: 1px solid #ddd;
    padding-left: 10px;
    &:focus {
      border-color: #ccc;
    }
  }
  .dropdown-menu {
    padding: 0;
  }
  .ui-select-choices-row {
    a {
      padding: 6px 10px;
    }
    &.active {
      a {
        background-color: $blue-bright;
      }
    }
  }
}

.btn-default-focus {
  color: #333;
  background-color: #bbb;
  border-color: $blue-bright;
  text-decoration: none;
  //outline: none;
  // outline-offset: -2px;
  //box-shadow: none;
}

.config-user {
  label {
    font-weight: normal;
  }
}