.btn-product-add {
  background: none;
  padding: 0;
  margin: 10px;
  margin-left: 15px;
  color: #666;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  .fa {
    font-size: 16px;
    color: #999;
  }
  // .btn-hover-content {
  //   opacity: 0;
  //   transition: opacity 300ms ease;
  //   font-size: 12px;
  //   color: #666;
  //   padding-left: 2px;
  //   position: relative;
  //   top: -2px;
  // }
  &:hover {
    color: $green;
    .fa {
      color: $green
    }
    // .btn-hover-content {
    //   opacity: 1
    // }
  }
}

.btn-location-add {
  margin: 0 0 0 5px;
}


.line-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  background: #fff;
  &.item {
    color: #999;
    // margin-left: 15px;
    @include MQ(S) {
      // margin-left: 50px;
    }
  }
  &:hover {
    .drag-handle {
      opacity: 1;
    }
  }
  .checkbox {
    margin: 0;
    padding-top: 6px;
    input {
      &:focus {
        border-color: #333;
        outline: none;
      }
    }
  }
}

.angular-ui-tree-nodes .angular-ui-tree-nodes {
  padding-left: 0;
}

.item-note {
  padding-right: 5px !important;
  padding-left: 5px !important;
  &.has-note {
    color: $blue-bright;
  }
}

.line-group-left {
  flex-grow: 2;
}

.line-group-left,
.line-group-right {
  display: flex;
  align-items: center;
}

.line-item-checkbox {
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}

.form-control-number {
  padding: 5px 10px;
  margin-left: auto;
  margin-right: auto;
  height: 30px;
  background: #f5f5f5;
  border: none;
  margin-bottom: 3px;
  text-align: right !important;
  &:focus {
    border: 1px solid #ccc;
    outline: none;
    box-shadow: none;
  }
}

.input-white .form-control-number {
  background: white;
  padding: 0;
  margin: 0;
}

.right-item {
  width: 35px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  @include MQ(S) {
    width: 92px;
  }
  // .project-add-products & {
  //   width: 50px;
  // }
}

.right-item-last {
  @include MQ(Sneg) {
    width: 24px;
  }
}

.project-add-products,
.review-products {
  @include MQ(Sneg) {
    .line-group-right {
      // display: none;
    }
  }
}

.review-products {
  @include MQ(Sneg) {
    .line-item {
      display: block;
    }
    .line-item-2nd-level {
      display: flex;
    }
    .mobile-inputs {
      flex-wrap: wrap;
      padding: 0 5px;
    }
    .mobile-input-wrapper {
      display: flex;
      align-items: baseline;
      background: #f0f0f0;
      padding: 2px;
      border: 1px solid #eee;
      margin-right: 2px;
      margin-bottom: 2px;
      input {
        width: 60px;
        text-align: left !important;
        padding: 0;
        display: inline-block;
        height: auto;
        margin: 0;
      }
    }
    // .line-group-right {
    //   display: block;
    // }
  }
}

.mobile-label {
  font-size: 12px;
  margin-right: 3px;
  font-weight: 600;
  @include MQ(S) {
    display: none;
  }
}

.line-item-input {
  text-align: left;
  &[disabled] {
    background: none;
    box-shadow: none;
  }
}

.product-title {
  font-size: 16px;
  width: 100%;
  &:hover {
    // background: #f5f5f5;
    color: $blue-bright;
    cursor: pointer;
    .fa {
      color: $blue-bright;
    }
  }
}

.product .line-group-left {
  .form-control {
    background: none;
    padding: 0;
    border: none;
    height: auto;
    padding-left: 16px;
    font-size: 16px;
    width: 500px;
  }
}

.item-title {
  font-size: 14px;
  color: #999;
}

.btn-add-item {
  @include MQ(S) {
    margin-left: 62px;
  }
}

.input-toggle {
  width: 157px;
  background: #D8E0E6;
  // border: 2px solid #D8E0E6;
  padding: 2px;
  margin: 2px 0;
  position: relative;
  box-shadow: inset 0 0px 8px rgba(0, 0, 0, 0.2);
  input {
    background: #fff;
    border: 1px solid #ccc;
    width: 72px;
    margin: 0;
    z-index: 1;
    position: relative;
    box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
    transform: translate3D(0,0,0);
    // transition: transform 300ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
    transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}

.toggle-btns {
  position: absolute;;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .btn {
    font-size: 10px;
    margin-top: 3px;
    width: 50%;
    float: left;
    text-transform: uppercase;
    color: rgba(0,0,0,0.3);
  }
}

.input-right-active {
  input {
    // margin-left: auto;
    transform: translate3D(79px,0,0);
  }
}

// Locactions

.location-wrapper {
  background: #f5f5f5;
  border: 1px solid #ddd;
  margin-bottom: 20px;
}

.location {
  background: #f5f5f5;
  padding: 10px 0 10px 10px;
  border-bottom: 1px solid #ddd;
  .btn {
    // color: #888;
    .fa {
      // color: #666;
    }
  }
}

.location-title {
  font-size: 14px;
  margin-right: 10px;
  text-transform: uppercase;
  @include MQ(S) {
    font-size: 20px;
  }
  span {
    color: #ccc;
  }
}

.location-label {
  text-transform: uppercase;
  font-size: 12px;
  color: #999;
  text-align: center;
  @include MQ(S) {
    text-align: right;
  }
}

.label-qty {
  text-align: center;
}

.edit-location-name {
  .form-control {
    font-size: 20px;
    background: none;
    padding: 0;
    border: none;
    height: auto;
  }
}

.location-price {
  font-size: 12px;
  padding: 0 5px;
  text-transform: uppercase;
  color: #999;
}


// UI Tree

.drag-handle {
  background: $green;
  background-size: 8px 5px;
  text-align: center;
  width: 12px;
  opacity: 0;
  padding: 0;
  height: 40px;
  .fa {
    color: rgba(255,255,255,0.5);
    font-size: 12px;
    line-height: 43px;
  }
}

.angular-ui-tree-empty {
  background: #fff;
  color: #fff;
  border-color: #fff;
}

.angular-ui-tree-placeholder {
  background: #f0f0f0;
  border: 1px solid #ccc;
}

.project-add-products .angular-ui-tree-nodes .angular-ui-tree-nodes {
  padding-left: 0;
  .angular-ui-tree-placeholder {
    margin-left: 50px;
  }
}

.add-form {
  margin: 5px;
  .form-control {
    border: none;
    background: #ffff;
    border-radius: 2px;
    padding-left: 10px;
    border: 1px solid #ddd;
    height: auto;
    padding: 10px 12px;
  }
}

.location-add-form {
  .form-control {
    // font-size: 20px;
    background: #fff;
  }
}

// Totals

.price-totals {
  display: flex;
  background: #a0b2bc;
  color: #fff;
  padding: 7px 15px;
  font-size: 16px;
  margin-top: 20px;
  align-items: center;
  @include MQ(S) {
    font-size: 20px;
  }
  @include MQ(Sneg) {
    flex-wrap: wrap;
  }
  h3 {
    margin: 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    // font-weight: normal;
    // font-size: 20px;
  }
  .total-one-off {
    @include MQ(S) {
      margin-left: auto;
    }
  }
  .total {
    margin-right: 5px;
    @include MQ(S) {
      margin-right: 10px;
    }
    @include MQ(Sneg) {
      // width: 100%;
    }
  }
  .total-label {
    font-size: 14px;
    color: rgba(0,0,0,0.5);
    font-weight: 500;
    text-transform: uppercase;
    @include MQ(Sneg) {
      width: 100%;
      font-size: 12px;
    }
  }
}

.product-type-return {
  background: rgba(255, 211, 197, 0.38);
}
