.filter-wrapper {
  margin-bottom: 10px;
  flex-wrap: wrap;
  align-items: center;
  @include MQ(S) {
    display: flex;
  }
}

.view-filters {
  display: flex;
  flex-wrap: wrap;
  @include MQ(Sneg) {
    justify-content: space-between;
  }
  label {
    text-transform: uppercase;
    font-weight: normal;
    color: #999;
    font-size: 12px;
    margin-bottom: 0;
    margin-right: 10px;
  }
  .filter-item {
    // margin-right: 5px;
    margin-bottom: 5px;
    flex-wrap: wrap;
    align-items: center;
    @include MQ(L) {
      display: flex;
    }
    @include MQ(Sneg) {
      width: 48%;
    }
    @include MQ(S) {
      margin-right: 20px;
    }
  }
  .dropdown-toggle {
    min-width: 120px;
    background: #f5f5f5;
    border: 1px solid #ddd;
    padding: 6px 12px;
    display: block;
    position: relative;
    @include MQ(S) {
      min-width: 130px;
    }
    &:hover {
      text-decoration: none;
      background: #eee;
    }
    .caret {
      background: #eee;
      height: 100%;
      border: none;
      border-left: 1px solid #ddd;
      text-align: center;
      width: 30px;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 0;
      &:before {
        font-family: "FontAwesome";
        content: '\f078';
        line-height: 30px;
        font-size: 15px;
      }
    }
  }
}