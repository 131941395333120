.sidebar {
  width: 300px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  transition: transform 300ms ease;
  z-index: 10;
  overflow-y: scroll;
}

.main-content-header {
  transition: left 300ms ease;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  @include MQ(S) {
    left: 300px;
  }
}

.main-content-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  transition: left 300ms ease;
  @include MQ(S) {
    left: 300px;
  }
}

.sidebar-closed {
  .sidebar {
    transform: translate3d(-300px,0,0);
  }
  .main-content-wrapper {
    left: 0;
  }
  .main-content-header {
    left: 0;
  }
}

.tab-content {
  position: relative;
}