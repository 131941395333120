.schedule-wrapper {
  background: #f0f0f0;
  // position: fixed;
  // top: 120px;
  // left: 250px;
  // bottom: 0;
  // right: 0;
  // padding: 20px 0;
  // transition: all 300ms ease;
  // overflow-y: scroll;
  // overflow-x: scroll;
  // .sidebar-open & {
  //   left: 550px;
  // }
  min-height: calc(100vh - 49px);
  // min-height: 100vh;
  padding-top: 69px;
  padding-left: 250px;
  transition: all 300ms ease;
  &.sidebar-collapse {
    padding-left: 0;
    .section-sidebar {
      left: -250px;
    }
  }
  .btn-collapse {
    &:focus {
      outline: none;
    }
  }
}

.section-sidebar {
  position: fixed;
  top: 119px;
  left: 0;
  bottom: 0;
  background: #fff;
  border-right: $border-light;
  z-index: 10;
  width: 250px;
  overflow-y: scroll;
  padding: 15px;
  transition: all 300ms ease;
  .sidebar-open & {
    left: 300px;
  }
  h2 {
    margin-top: 5px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
}

.delivery-schedule {
  .section-sidebar {
    overflow-y: visible;
  }
}

.current-filter-date {
  white-space: nowrap;
}

.date-range-filter {
  padding: 10px 15px;
  // position: relative;
  // &:hover {
  //   &:before {
  //     cursor: pointer;
  //   }
  // }
  // &:before {
  //   content: '\f073';
  //   font-family: FontAwesome;
  //   position: absolute;
  //   top: 15px;
  //   left: 25px;
  //   pointer-events: none;
  // }
  // input {
  //   background: #f6f6f6;
  //   border: 1px solid #ddd;
  //   border-radius: 2px;
  //   width: 100%;
  //   padding: 5px 10px 5px 28px;
  //   font-size: 12px;
  //   &:focus {
  //     outline: none !important;
  //     background: #ddd;
  //     border-color: #ccc
  //   }
  // }
}

.unscheduled-filters {
  margin-bottom: 15px;
  border-bottom: $border-light;
  position: relative;
  &:before {
    content: '\f002';
    font-family: FontAwesome;
    position: absolute;
    left: 12px;
    top: 9px;
  }
  .form-control {
    background: #f0f0f0;
    border: none;
    box-shadow: none;
    padding-left: 33px;
  }
  .btn {
    background-color: #f0f0f0;
  }
}

.unscheduled-tabs {
  margin-left: -15px;
  margin-right: -15px;
  .nav-tabs {
    padding-left: 15px;
    padding-right: 15px;
  }
  .nav-tabs > li > a {
    font-weight: bold;
    font-size: 13px;
    padding: 10px 11px;
    span {
      font-weight: normal;
      font-size: 11px;
    }
  }
}

.unscheduled-tab-content {
  padding: 10px 15px;
  li {
    margin-bottom: 3px;
  }
  .run {
    border: 1px solid #ccc;
  }
}

.unscheduled-list {
  overflow: auto;
  max-height: 450px;
}

.unscheduled-list .angular-ui-tree-nodes {
  // overflow: scroll;
  overflow-x: hidden;
  height: 100%;
}

// Schedule Header

.schedule-screen {
  .section-header {
    display: flex;
    border-bottom: $border-light;
    align-items: center;
    padding: 0 30px;
    height: 69px;
    // @include MQ (Sneg){
    //   flex-direction: column;
    //   height: auto;
    // }
  }
  .page-title {
    width: auto;
    margin-right: 30px;
    margin-bottom: 0;
  }
}

.schedule-actions {
  display: flex;
  flex: auto;
  align-items: center;
  height: 100%;
  // @include MQ (Sneg){
  //   flex-direction: column;
  //   height: auto;
  // }
}

.schedule-filters {
  margin-left: auto;
  // .ui-select-container {
  //   width: 200px !important;
  // }
  .ui-select-match {
    &:focus {
      outline: none;
    }
    a.btn {
      margin-right: 20px !important;
      background: none;
      padding: 0 6px;
      font-size: 16px;
      line-height: 16px;
      border: none;
      .glyphicon:before {
        font-family: FontAwesome;
        content: '\f00d';
        color: #666;
      }
    }
  }
}

.schedule-date-picker {
  .form-control {
    display: none;
  }
}

.view-picker {
  min-width: 192px;
  .btn {
    background: none;
    color: $blue-bright;
    &.active-view {
      background: $blue-bright;
      color: #fff;
      &:hover {
        background: darken($blue-bright, 10%);
        color: #fff;
      }
    }
  }
}

.date-picker {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  // padding: 5px;
  border-radius: 4px;
  // .dropdown-menu {
  //   left: auto !important;
  //   right: 0;
  // }
  border: 1px solid #ccc;
  .btn {
    padding: 6px 5px;
    &:first-child {
      border-right: 1px solid #ccc;
    }
    &:last-child {
      border-left: 1px solid #ccc;
    }
  }
}

.schedule-date-picker {
  margin-left: 5px;
  margin-right: 5px;
  .btn {
    border: none !important;
  }
}

// .cell {
//   display: inline-block;
//   width: 220px;
//   margin: 8px;
// }

.odd {
  .run-list {
    background: #fff;
  }
}
.even {
  .run-list {
    background: #f0f0f0;
  }
}

// Run

.run-wrapper {
  margin-bottom: 3px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  // display: inline-block;
}

.run {
  border-bottom: 1px solid #ccc;
  padding: 5px 4px 5px 8px;
  font-family: $font-family-sans-serif;
  cursor: pointer;
  .progress {
    // background-color: #6b5e5e;
    background-color: #f6f6f6;
    color: #999;
  }
  .progress-bar {
    background-color: #40b35f;
  }
}

.run-top {
  display: flex;
  font-size: 13px;
}

.run-name {
  font-weight: bold;
}

.run-name-wrapper {
  // width: 120px;
}

.run-count {
  text-transform: uppercase;
  color: #888;
  font-weight: bold;
  margin-right: 5px;
}

.run-toggle {
  margin-left: auto;
  margin-top: -5px;
  i {
    transition: transform 300ms ease;
  }
  .run-open {
    i {
      transform: rotate(180deg);
    }
  }
}

.run-bottom {
  font-size: 12px;
  color: #888;
  display: flex;
  align-items: center;
}

.run-time {
  margin-right: 5px;
}

.run-indicator {
  font-weight: bold;
  padding-left: 3px;
  padding-right: 3px;
}

.run-deliverable-total {
  color: $green-dark;
}

.run-work-order-count {
  color: #333;
}

.run-footer {
  margin-right: 5px;
  .progress {
    margin-bottom: 0;
  }
  .progress-bar {
    text-align: right;
    font-weight: bold;
    font-size: 12px;
    color: rgba(0,0,0,0.3);
    span {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
}

.users-assigned {
  display: flex;
  margin-left: -2px;
  margin-right: -2px;
}

.user-image-container {
  width: 30px;
  height: 30px;
  margin-left: 2px;
  margin-right: 2px;
  background: #e6e6e6;
  border-radius: 4px;
  img {
    border-radius: 6px;
    max-width: 100%;
    height: auto;
  }
}

.schedule-edit-dropdown {
  margin-left: auto;
  .dropdown-toggle {
    padding: 3px 9px !important;
  }
}

.run-list {
  .angular-ui-tree-empty {
    min-height: 20px;
    background: transparent;
    text-align: center;
    border: none;
    &:before {
      content: 'No runs';
      color: #999;
      padding-top: 2px;
      padding-left: 10px;
      font-size: 10px;
    }
  }
}


// UnAvailalbe

.run-list.unavailable {
  background: #ccc;
  .angular-ui-tree-empty {
    border: none;
    // padding-top: 10px;
    &:before {
      content: 'Unavailable';
      text-transform: uppercase;
      font-size: 14px;
      color: rgba(255,255,255,0.8);
      font-weight: bold;
    }
  }
}

.run-list.unavailable-with-run {
  background: #fb8a8a;
  text-align: center;
  .angular-ui-tree-nodes {
    padding-top: 5px;
  }
  &:before {
    content: 'Unavailable';
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
  }
}




.run-list-items {
  background: #ddd;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 2px;
  padding-bottom: 0;
  border-bottom: 1px solid #ccc;
  .angular-ui-tree-empty {
    background: #ddd;
    border: none;
    &:before {
      content: 'Empty';
    }
  }
}


.add-run-form,
.edit-run-form {
  background: #ddd;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #ccc;
  .form-control {
    font-size: 12px;
    margin-bottom: 3px;
  }
  .btn {
    font-weight: bold;
    font-size: 13px;
  }
}

.assignee-dropdown {
  width: 100%;
  z-index: 1000;
  .btn {
    text-align: left;
    background: #fff;
    font-size: 12px;
    font-weight: normal;
    color: #555;
    border: 1px solid #ccc;
  }
}

// Work Order Card

.work-order-wrapper {
  background: #f5f5f5;
  border-radius: 3px;
  padding: 5px 4px 5px 7px;
  border: 1px solid #ddd;
  border-bottom: 2px solid #ccc;
  cursor: pointer;
  margin-bottom: 3px;
  font-family: $font-family-sans-serif;
}

.work-order-completed {
  background: #ddd;
  border-color: #ccc;
  opacity: 0.6
}

.work-order-top {
  display: flex;
  font-size: 13px;
  line-height: 120%;
  margin-bottom: 4px;
  position: relative;
}

.work-order-name {
  // font-weight: bold;
  .summer & {
    padding-left: 10px;
  }
}

.summer-indicator-icon {
  background: url(../img/summer-indicator.png);
  width: 20px;
  height: 23px;
  position: absolute;
  top: -3px;
  left: -14px;
}

.work-order-zone {
  // font-weight: 300;
  // padding-left: 3px;
  margin-right: 5px;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  background: #aaa;
  height: 18px;
  &:hover {
    background: #666;
    color: #fff;
    text-decoration: none;
  }
}

.work-order-counts {
  margin-left: auto;
  display: flex;
}

.work-order-count {
  font-weight: bold;
  padding-left: 2px;
  padding-right: 2px;
  min-width: 19px;
  font-size: 12px;
  min-width: 23px;
  .fa {
    font-size: 10px;
    position: relative;
    top: -1px;
    margin-left: 1px;
  }
}

.work-order-count-serviceable {
  color: #4a1717;
  .fa {
    // font-size: 11px;
  }
}

.work-order-count-in {
  color: #4A90E2;
}

.work-order-count-out {
  color: #49A92A;
}

.work-order-bottom {
  font-size: 11px;
  line-height: 120%;
  color: #888;
  display: flex;
  align-items: center;
  .schedule-edit-dropdown {
    margin-right: -3px;
  }
}

// .work-order-date {
//   padding-top: 2px;
// }

.timing-alert {
  margin-top: 3px;
  display: flex;
  font-size: 10px;
  line-height: 120%;
}

.timing-alert-border {
  width: 4px;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 0;
  background: $brand-danger;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.timing-sensitive-alert {
  padding-right: 3px;
  color: $brand-danger;
}

.schedule-container {
  position: relative;
  overflow: auto;
  max-height: 750px;
  @include MQ (XLneg) {
    max-height: 650px;
  }
}
.schedule-calendar,
.roster-calendar {
  //display: flex;
  // overflow: scroll;
  // padding-left: 5px;
  // padding-right: 5px;
  // padding-top: 17px;
  position: relative;
  min-height: calc(100vh - 120px);
}

.schedule-day {
  .schedule-screen.Week & {
    flex: auto;
    // margin-left: 1px;
    // margin-right: 1px;
    width: percentage(1/8);
    min-width: 100px;
    @include MQ (XLneg) {
      min-width: 100px;
      width: percentage(1/8);
    }
  }
}

// .roster-day {
//   flex: auto;
//   width: percentage(1/7);
//   min-width: 200px;
// }

.placeholder-header {
  background: #e6e6e6;
  border-bottom: 1px solid #d6d6d6;
  border-right: 1px solid #d6d6d6;
  position: sticky;
  left: 0;
  top: 0;
}

.top-header {
  padding-top: 10px !important;
  background: #e6e6e6;
  border-bottom: 1px solid #d6d6d6;
  border-right: 1px solid #d6d6d6;
}

.day-header {
  font-weight: bold;
  text-align: center;
  margin-bottom: 8px;
  margin-top: 8px;
  font-size: 16px;
}

.day-link {
  color: #333;
  &:hover {
    color: $blue-bright;
    text-decoration: none;
    cursor: pointer;
  }
}

.day-date {
  font-size: 12px;
  font-weight: normal;
}

.assignee {
  // background: #fff;
  // margin-bottom: 15px2
  // border: 1px solid #2
  // border-radius: 3px;2
  width: 100%;
}

.assignee-name {
  font-weight: bold;
  word-break: break-word;
}


// Week view
.sidebar-closed {
  .schedule-screen.Week .days-row {
    // @include MQ (XLneg) {
    //   padding-right: 250px !important;
    // }
    padding-right: 0 !important;
  }
  .schedule-screen.Week {
    .sidebar-collapse {
      .days-row {
        @include MQ (XLneg) {
          padding-right: 0 !important;
        }
        padding-right: 0x !important;
      }
    }
  }
}

.days-row {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
}

.schedule-screen.Week {
  .days-row {
    padding-right: 0;
    // @include MQ (XLneg) {
    //   padding-right: 550px;
    // }
  }
  .sidebar-collapse {
    .days-row {
      padding-right: 0 !important; 
      // @include MQ (Lneg) {
      //   padding-right: 300px !important;
      // }
    }
  }
  .run-list {
    width: percentage(1/8);
    min-width: 100px;
  }
  @include MQ (Lneg) {
    .run-list {
      min-width: 100px;
      min-height: 80px;
    }  
  }
}

.schedule-screen {
  &.Week {
    // .schedule-calendar > .assignee-wrapper{
    //   margin-top: 65px;
    //   .servicingSchedule-manage & {
    //     margin-top: 48px;
    //   }
    // }
    // .schedule-calendar > .assignee-wrapper ~ .assignee-wrapper{
    //   margin-top: 0;
    // } 
  }
  &.Day {
    .days-row {
      background: #f0f0f0;
      border-bottom: 1px solid #d6d6d6;
    }
    .schedule-calendar {
      margin-top: 56px;
      .servicingSchedule-manage & {
        margin-top: 38px;
      }
    }
  }
}
// Day View
.schedule-Delivery {
  .schedule-screen.Day {
    .schedule-calendar{
      display: flex;
      flex-direction: row;
    }
    .assignee {
      flex-direction: column;
    }
    .run-list {
      .new-run {
        position: relative;
      }
    }
    .work-order-wrapper{
      max-width: 183px;
    }
  }
}

.schedule-screen.Day {
  .schedule-day {
    width: 100%;
  }
  // .placeholder-header {
  //   width: 242px;
  // }
  .day-header {
    max-width: 300px;
  }
  .assignee-wrapper {
    display: flex;
    // justify-content: center;
  }
  .assignee {
    // flex: auto;
    max-width: 500px;
    // margin-left: 5px;
    // margin-right: 5px;
  }
  .day-link {
    &:hover {
      color: #333;
    }
  }
}

.assignee-header {
  //width: 200px;
  font-size: 16px;
  flex-shrink: 0;
  border-bottom: 1px solid #d6d6d6;
  border-right: 1px solid #d6d6d6;
  padding-top: 10px;
  background: #e6e6e6;
  position: sticky;
  left: 0;
  z-index: 1;
  // background: #333;
  // color: #fff;
  // padding: 5px 10px;
  text-align: center;
  //display: flex;
  // justify-content: center;
  // margin-bottom: 2px;
  // border-radius: 3px;
  // align-items: center;
  // min-width: 100px;
  // width: percentage(1/8);
  .Day & {
    width: 200px;
  }
  .Week & {
    min-width: 100px;
    width: percentage(1/8);
  }

  .assignee-action {
    margin-top: -2px;
    margin-left: auto;
    .btn {
      padding: 0;
      transition: transform 300ms ease;
      color: #ccc;
      &.open {
        transform: rotate(135deg);
        color: #fff;
      }
      &:hover, &:focus {
        color: #fff;
      }
    }
  }
}

// Date Picker overrides

.schedule-date-picker {
  * {
    &:focus {
      outline: none;
    }
  }
  .dropdown-menu {
    margin-top: 0;
    padding-top: 0;
  }
  .glyphicon {
    font-family: FontAwesome;
  }
  .glyphicon-chevron-left {
    &:before {
      content: '\f060';
    }
  }
  .glyphicon-chevron-right {
    &:before {
      content: '\f061';
    }
  }
  thead {
    background: #f0f0f0;
    color: #777;
    margin-bottom: 10px;
    th {
      border-bottom: 1px solid #ccc !important;
    }
  }
  .btn-default {
    border: none;
  }
  .btn-danger, .btn-info, .btn-success {
    background: none;
    color: $blue-bright;
    border: none;
    text-transform: none;
  }
}

// Ui Tree Overrides

.angular-ui-tree-drag {
  transform: rotate(2deg);
  cursor: pointer;
}

.schedule {
  .angular-ui-tree-nodes .angular-ui-tree-nodes {
    padding-left: 2px;
  }
}

.angular-ui-tree-placeholder {
  width: 100% !important;
  background: #eee;
  border: dashed 2px #aaa;
  box-shadow: inset 0px 0px 30px 8px rgba(0,0,0,0.2);
}

// Roster schedule

.config-roster {
  .section-content {
    // padding-top: 78px;
    // padding-left: 0;
    // padding-right: 0;
  }
  .placeholder-header {
    width: 200px;
    flex-shrink: 0;
  }
  .assignee-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .assignee.even {
    background: #F9f9f9;
  }
}

.schedule-day {
  .config-roster & {
    flex: auto;
    width: percentage(1/7);
  }
}

.roster-item {
  flex: auto;
  width: percentage(1/7);
  display: flex;
  justify-content: center;
  align-items: center;
  // min-height: 80px;
  padding: 5px;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.roster-active {
  background: #BCE6FE;
  border-color: #fff;
}
