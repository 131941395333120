.view-animate-swipe.ng-enter,
.view-animate-swipe.ng-hide-remove {
  backface-visibility: hidden;
  transform: translate3d(-50%,0,0);
  transition: transform 300ms $ease, opacity 300ms ease;
  opacity: 0;
}

.view-animate-swipe.ng-enter.ng-enter-active,
.view-animate-swipe.ng-hide-remove.ng-hide-remove-active {
  transform: translate3D(0,0,0);
  opacity: 1;
}

.view-animate-fade.ng-enter,
.view-animate-fade.ng-hide-remove {
  opacity: 0;
  transition: opacity 1000ms ease;
}

.view-animate-fade.ng-enter.ng-enter-active,
.view-animate-fade.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
}


