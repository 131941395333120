.table-lg {
  td {
    padding: 15px 8px !important;
  }
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f7f9fa;
}

thead {
  background: #8e979c;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  th {
    padding: 8px 8px 8px 8px !important;
    border-bottom: 2px solid #687175 !important;
    font-weight: bold;
    border-right: 1px solid rgba(255,255,255,0.1);
  }
}

.sortable {
  position: relative;
  &:hover {
    cursor: pointer;
    background: #687175;
  }
}

.active-descending,
.active-ascending {
  &:hover {
    cursor: pointer;
  }
  &:after {
    content: '\f107';
    font-family: FontAwesome;
    font-size: 20px;
    line-height: 10px;
    position: absolute;
    top: 11px;
    right: 10px;
  }
}

.active-ascending {
  &:after {
    content: '\f106';
  }
}

.table-scroll {
  border-collapse: separate;
}

.table-scroll thead {
  background:#8e979c;
  position: sticky;
  padding:.5rem;
  z-index:2;
  top: 0;
  border-right: 1px solid #687175 !important;
}

.table-scroll thead tr > :first-child {
  z-index:3;
  left:0;
  top:0;
}

.table-scroll tbody tr > :first-child {
  background:#f7f9fa;
  position: sticky;
  z-index:1;
  left:0;
}
