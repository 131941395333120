.quote {
  .section-sub-title {
    padding-bottom: 15px;
    padding-left: 10px;
  }
  .product.line-item {
    padding: 18px 0;
    @include MQ(S) {
      padding: 9px 0;
    }
  }
  .line-item.item {
    padding: 10px 0;
    background: #f6f6f6;
  }
  .location-label {
    text-align: center;
  }
  .line-group-click-group {
    display: flex;
  }
  .section-content {
    @include MQ(XSneg) {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      margin-bottom: 50px;
    }
  }
  .line-item.item {
    margin-left: 4px;
  }
  .item-title {
    font-size: 12px;
    color: #555;
    margin-left: 5px;
  }
  .form-actions {
    @include MQ(Sneg) {
      background: #fff;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      padding: 0;
      // justify-content: ;
      .btn {
        border-radius: 0;
        border-color: darken($blue-bright, 10%);
        flex-grow: 1;
      }
      .btn-ready {
        border-color: darken($green, 10%);
      }
      .btn[disabled] {
        border-color: #999;
      }
    }
  }
  // Product Status indications
  .product {
    border-left: 7px solid transparent;
  }
  .input-toggle {
    background: inherit;
    box-shadow: inherit;
    input {
      border: none;
      padding: 0;
    }
  }
}

.edit-quote {
  position: absolute;
  top: 0;
  right: 0;
}

.load-quote {
  background-color: #eee;
  div.main-content-header,
  div.sidebar {
    display: none;
  }

  .main-content {
    max-width: 1024px;
    margin: 0 auto;
    background: #fff;
  }

  .main-content-wrapper {
    left: 0;
  }
}

.quote-header {
  background-color: #333;
  color: #fff;
  padding-left: 30px;
  padding-right: 30px;
}

.quote-content {
  padding: 30px;
}

.selected-contact {
  display: flex;
  align-items: center;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px 20px;
  color: $blue-bright;
  i {
    font-size: 20px;
    margin-right: 5px;
  }
}

.quote-template {
  textarea {
    width: 100%;
    padding: 10px;
    border-color: #ddd;
    border-radius: 4px;
  }
}

.copy-icon {
  position: relative;
  top: -2px;
}

.quote-contacts {
  margin-bottom: 30px;
  margin-left: -15px;
  margin-right: -15px;
  @include MQ(S) {
    display: flex;
  }
  .control-label {
    width: 70px;
    font-weight: 300;
    font-size: 11px;
    text-transform: uppercase;
    &.long-label {
      width: 135px;
      min-width: 135px;
    }
  }
}

.quote-box-clean {
  margin-left: 15px;
  margin-right: 15px;
  padding: 10px 20px;
}

.quote-box {
  margin-left: 15px;
  margin-right: 15px;
  background-color: #f6f6f6;
  border: 1px solid #ddd;
  padding: 10px 20px;
}

.quote-totals {
  display: flex;
  justify-content: flex-end;
}

.quote-total {
  border: 1px solid #ccc;
  color: #666;
  padding: 10px 20px;
  margin-left: 5px;
  background-color: #f5f5f5;
  span {
    margin-left: 3px;
    font-size: 20px;
    color: #333;
    font-weight: 600;
  }
}

.accept-quote-modal {
  textarea {
    font-size: 12px;
  }
}

.table-quotes table tbody {
  tr.status-Approved {
    background-color: #c3ffc3;
  }
  tr.status-Declined {
    background-color: rgb(255, 207, 195);
  }
}

.sales-quote {
  .main-content {
    height: auto;
  }
}

@media print {
  @page {
    margin: 0;
  }
  .quote {
    .logo {
      // margin-left: 15px;
      background: #333 !important;
      flex: 1 0 0px;
    }
    .quote-header-middle {
      background: #333 !important;
      flex: 1 0 0px;
    }
    .quote-header-right {
      flex: 1 0 0px;
      text-align: right;
    }
    .quote-contacts {
      display: flex !important;
    }
    .quote-box {
      padding: 0 !important;
      border: none !important;
      &.print-border {
        border: 1px solid #ddd !important;
        padding: 10px 20px !important;
      }
    }
    .location-wrapper {
      border: none;
    }
    .line-item {
      padding: 5px 0 5px 10px !important;
      &.product {
        border-top: 1px solid #ddd;
        border-bottom: none;
        border-left: none;
      }
      &.item {
        margin-left: 30px;
        border: none;
        padding: 0 0 5px 0 !important;
      }
    }
    .quote-header > .row {
      display: flex !important;
    }
    .right-item {
      width: 92px !important;
    }
  }
}
