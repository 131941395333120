.page-title,
.breadcrumb-title {
  margin-top: 0;
  // margin-bottom: 20px;
  font-weight: 500;
  font-size: 18px;
  @include MQ(S) {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  a {
    color: $text-color;
  }
}

.page-title.has-filters {
  margin-bottom: 0;
}

.section-title {
  font-size: 15px;
  background: $green;
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin: 0;
  padding: 10px 3px;
  margin-bottom: 10px;
  @include MQ(S) {
    font-size: 24px;
    background: none;
    color: $text-color;
    text-align: left;
    font-weight: normal;
    padding-left: 0;
  }
}

.hp-label {
  text-transform: uppercase;
  color: #555;
  font-size: 12px;
  font-weight: bold;
}

.text-subtle {
  color: #999;
}

.text-sm {
  font-size: 12px;
}

.semibold {
  font-weight: 600;
}