.create-work-order-modal {
  padding: 20px;
  @include MQ(S) {
    padding: 40px 80px;
  }
}

.select-work-order-modal {
  padding: 20px;
  @include MQ(S) {
    padding: 40px 80px;
  }
}

// Exchange & Return items modal
.return-exchange--wrapper {
  padding: 20px 0;
  h3 {
    padding-left: 30px;
    font-size: 20px;
    font-weight: 700;
  }
}

.return-exchange--header {
  display: flex;
  justify-content: flex-end;
  .qty-label {
    width: 100px;
  }
  .exc-label {
    width: 140px;
    margin-right: 90px;
  }
  .rtn-label {
    width: 140px;
    margin-right: 57px;
    padding-bottom: 10px;
  }
}

.return-exchange--items {
  padding: 5px 30px;
  @include MQ(Sneg) {
    display: block;
  }
  .min-max-wrapper {
    text-align: center;
  }
}

.exchange-items-product-items {
  padding: 5px 30px;
  @include MQ(Sneg) {
    display: block;
  }
}

.min-max-wrapper {
  display: flex;
  width: 180px;
}

.min-max-label {
  width: 180px;
}

.select-work-order {
  .options-wrapper {
    display: flex;
  }
  .option-type {
    font-weight: 700;
    margin-right: 5px;
  }
}

.work-order-staging,
.work-order-delivering,
.work-order-completed,
.review-products {
  .product.line-item {
    padding: 9px 0;
  }
  .location-label {
    text-align: center;
  }
}

.work-order-picking {
  .product.line-item {
    padding: 18px 0;
    @include MQ(S) {
      padding: 9px 0;
    }
  }
  .line-item.item {
    padding: 10px 0;
    background: #f6f6f6;
  }
  .location-label {
    text-align: center;
  }
  .line-group-click-group {
    display: flex;
  }
}

.work-order-completed {
  .right-item {
    padding: 0;
  }
}

.work-order-staging,
.work-order-delivering,
.work-order-completed,
.review-products {
  .line-item.item {
    padding: 5px;
    background: #f6f6f6;
    @include MQ(S) {
      padding: 5px 5px 5px 35px;
    }
  }
}

.work-order {
  .section-content {
    @include MQ(XSneg) {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      margin-bottom: 50px;
    }
  }
  .line-item.item {
    margin-left: 4px;
  }
  .item-title {
    font-size: 12px;
    color: #555;
    margin-left: 5px;
  }
  .form-actions {
    @include MQ(Sneg) {
      background: #fff;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      padding: 0;
      // justify-content: ;
      .btn {
        border-radius: 0;
        border-color: darken($blue-bright, 10%);
        flex-grow: 1;
      }
      .btn-ready {
        border-color: darken($green, 10%);
      }
      .btn[disabled] {
        border-color: #999;
      }
    }
  }
  // Product Status indications
  .product {
    border-left: 7px solid transparent;
    &.ready {
      border-left-color: $green;
      background: $green-light;
    }
    &.order {
      border-left-color: $blue-bright;
    }
  }
}

.edit-work-order {
  position: absolute;
  top: 0;
  right: 0;
}

.delivery-date {
  @include MQ(Sneg) {
    padding-left: 15px;
    padding-right: 15px;
  }
  display: flex;
  align-items: center;
  label {
    font-weight: normal;
  }
  .input-wrapper {
    margin-left: 10px;
    width: 170px;
  }
}

// Review Products

.btn-exchange {
  margin-right: 5px;
}

.date-filter {
  padding: 0;
  border: none;
  input.form-control {
    display: none;
  }
}

// Date picker

.filter-dropdown-menu {
  margin-top: 10px;
  background: #f6f6f6;
  .schedule-date-picker {
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
    table {
      border: 1px solid #ddd;
      background: #fff;
      thead th {
        border-right: none;
      }
    }
  }
  .date-range-selectors {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
    }
    .btn {
      color: $blue-bright;
      background: transparent;
    }
  }
  .filter-date-picker {
    .btn.active {
      background: $blue-bright;
      color: #fff;
    }
  }
  .date-rage-actions {
    .btn {
      background: $blue-bright;
      color: #fff;
      font-weight: bold;
      padding: 5px 30px;
      font-size: 14px;
    }
  }
}

.double-date {
  .date-pickers {
    display: flex;
  }
  div.schedule-date-picker {
    height: auto;
  }
}

.display-as-block {
  display: block !important;
}

.work-orders-section-header {
  .check-box-label{
    color: #5c6b7a;
    text-transform: none;
    font-size:14px;
    input{
      margin-right:5px;
    }
  }
  .view-filters {
    .filter-group{
      border:1px solid #ccc;
      border-radius: 2px;
      padding: 10px 10px 15px 10px;
    }
  }
}

.filter-item-group{
  li{
    padding:5px 0px;
  }
}

.advanced-filters-group{
  background:  #f6f6f6;
  padding: 20px;
  display: flex;
  margin-top:10px;
}

.work-orders-section-content {
  padding-top:30px !important;
}

.work-orders{
  display: flex;
  flex-direction: column;
}
.work-orders-section-header{
  position: static;
}