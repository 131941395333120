
.unfloat                      { float:none; }
.dot                          { border-radius:50%; }
.quiet                        { color:#7f7f7f; color: rgba(0,0,0,.5); }
.dark .quiet                  { color: #7f7f7f; color: rgba(255,255,255,.5);}
.center                       { text-align:center; }
.contain                      { position:relative; }
.clip                         { overflow:hidden; }
.hidden.hidden                { display:none; }
.text-left                    { text-align:left; }
.text-right                   { text-align:right; }
.space > *                    { margin-right:5px; }
img.inline, .inline           { display:inline-block; }
.break-word                   { word-wrap:break-word; }
.align-middle                 { vertical-align: middle;}
.align-top                    { vertical-align: top;}
.block                        { display:block; }
.block-important              { display:block !important; }
.scroll-h { overflow-x:auto; }
.scroll-v { overflow-y:auto; }
.capitalize { text-transform:capitalize; }
.z1   { z-index:1; }
.z10  { z-index:10; }
.z100 { z-index:100; }
.round                  { border-radius:4px; }
.round-top              { border-radius:4px 4px 0 0; }
.round-right            { border-radius:0 4px 4px 0; }
.round-bottom           { border-radius:0 0 4px 4px; }
.round-left             { border-radius:4px 0 0 4px; }
.round-topleft     { border-top-left-radius: 4px;}
.round-bottomleft  { border-bottom-left-radius: 4px;}
.round-topright    { border-top-right-radius: 4px;}
.round-bottomright { border-bottom-right-radius: 4px;}
.shadow { box-shadow: 0 0 25px 0 rgba(0,0,0,0.15); }
.uppercase { text-transform: uppercase; }
.noevents { pointer-events: none;}

.unround,
.pill.unround .unround  { border-radius:0;}

.caption {
  display:block;
}
.prose .caption {
  font-size: 12px;
  margin: -20px 0 20px
}

.truncate {
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden;
}

.menu-divider {
  width: 1px;
  height: 100%;
  background: #eee;
}

.w-100 {
  width: 100%;
}

// BassCSS Variables
$space-1: 5px;
$space-2: 15px;
$space-3: 30px;
$space-4: 50px;
$breakpoint-sm: '(min-width: 768px)';
$breakpoint-md: '(min-width: 992px)';
$breakpoint-lg: '(min-width: 1200px)';

.flex { display: flex }

@media #{$breakpoint-sm} {
  .sm-flex { display: flex }
}

@media #{$breakpoint-md} {
  .md-flex { display: flex }
}

@media #{$breakpoint-lg} {
  .lg-flex { display: flex }
}

.flex-column  { flex-direction: column }

.flex-wrap    { flex-wrap: wrap }

.items-start    { align-items: flex-start }

.items-end      { align-items: flex-end }

.items-center   { align-items: center }

.items-baseline { align-items: baseline }

.items-stretch  { align-items: stretch }

.self-start    { align-self: flex-start }

.self-end      { align-self: flex-end }

.self-center   { align-self: center }

.self-baseline { align-self: baseline }

.self-stretch  { align-self: stretch }

.justify-start   { justify-content: flex-start }

.justify-end     { justify-content: flex-end }

.justify-center  { justify-content: center }

.justify-between { justify-content: space-between }

.justify-around  { justify-content: space-around }

.content-start   { align-content: flex-start }

.content-end     { align-content: flex-end }

.content-center  { align-content: center }
c
.content-between { align-content: space-between }

.content-around  { align-content: space-around }

.content-stretch { align-content: stretch }

.flex-auto {
  flex: 1 1 auto;
  min-width: 0; /* 1 */
  min-height: 0; /* 1 */
}

.flex-even {
  flex: 1 0 0px;
}

.flex-single {
  flex: 1 0 0px;
}

.flex-double {
  flex: 2 0 0px;
}

.ml-auto { margin-left: auto }

.mr-auto { margin-right: auto }

.mx-auto { margin-left: auto; margin-right: auto; }

.scroll-x {
  overflow-x: scroll;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.bold {
  font-weight: bold;
}

.px-3 {
  padding: 0 30px;
}

.px-3-with-button {
  padding: 0 18px;
}

.pad-top-ten {
  padding-top: 10px;
}

.mx-1{
  margin-left:5px;
  margin-right:5px;
}
.mt-1{
  margin-top:5px;
}
.mb-1{
  margin-bottom: 5px;
}
.mr-1{
  margin-right:5px;
}

.d-flex{
  display: flex;
}
.justify-content-center{
  justify-content: center;
}
.align-items-center{
  align-items: center;
}

.w-100{
  width: 100%;
}