@import "variables";
@import "mixins";

// Bootstrap
@import "../../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";

// FontAwesome
$fa-font-path: "../fonts";
@import "../../../bower_components/font-awesome/scss/font-awesome.scss";

// Tachyon spacing - utitlity classes
@import "../../../bower_components/tachyons-spacing/css/tachyons-spacing";

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,400italic);

@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/vendor/_angular-drag.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/vendor/_angular-scrollable-table.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/vendor/_angular-select2.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/vendor/_angular-ui-notification.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/vendor/_angular-ui-tree.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/vendor/_daterangepicker.scss";
;
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_add-item.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_config.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_contacts.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_details.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_form.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_header.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_layout.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_login.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_main.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_modals.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_motions.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_notes.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_print.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_product_types.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_products.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_quote.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_regions.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_run-list.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_schedule.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_search.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_sidebar.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_spinner.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_stock-tags.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_tables.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_toggle.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_trucks.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_utilities.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/_work-orders.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/base/_buttons.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/base/_checkbox.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/base/_daterangepicker.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/base/_dropdowns.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/base/_filters.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/base/_text.scss";
@import "/Users/joshua/Desktop/HirePlants/hp-app/resources/assets/sass/components/base/_utilities.scss";
;

body {
  -webkit-font-smoothing: antialiased;
}

a.link {
  color: $blue-bright
}
