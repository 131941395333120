//max print area
// @page  
// { 
//     size: auto;   /* auto is the initial value */ 

//     /* this affects the margin in the printer settings */ 
//     margin: 5mm 5mm 5mm 5mm;  
// } 
@media screen{
    .print-only, .print-banner{
        display:none !important;
    }
}

@media print{
    // * { overflow-y: visible !important; }
    .screen-only {
        display: none !important;
    }

    .main-content-wrapper, .sidebar{
        position: static;
    }
    .location-wrapper{
        page-break-inside: avoid;
        margin-top:15px;
    }
    a[href]:after {
        content: none !important;
    }
    .print-only{
        display: block;
    }
    .sidebar, .main-content-header, .caret, .form-actions, .edit-dropdown{
        display:none !important;
    }

    .main-content{
        margin-top:0px !important;
        overflow: visible !important;
        display: block;
    }
    .filter-wrapper{
        .mt1{
            display: none;
        }
    }
    .section-header{
        border: none !important;
    }
    .main-actions ul {
        li a{
            display: none;
        }

        .active{
            display: block;
            position: absolute;
            left:15px;
            font-size:16px;
        }
    }

    .section-notes{
        position: absolute;
        display: block;
        width: 100%;
        left:15px;
    }
    .section-content{
        display: block !important;
        margin-top:15px;
    }
    .tab-content{
        display: block;
        width: 100%;
    }
    .collapse{
        display: block !important;
        height: auto !important;
    }
    .checkbox{
        display: none !important;
    }
    .print-banner{

        .row{
            display: flex !important;
            padding:0 15px;
        }
        .logo {
            margin-left: 15px;
            //background: #333 !important;
            flex: 1 0 0px;
            width: calu(3/12);
          }
          .quote-header-middle {
            margin-left: 15px;
            flex: 1 0 0px;
            width: calu(6/12);
          }
          .quote-header-right {
            flex: 1 0 0px;
            text-align: right;
            margin-right: 15px;
            width: calu(3/12);
          }
    }
    .work-order-print{
        .work-order-print-content{
            .quote-header{
                box-shadow: inset 0 0 0 1000px #333 !important;
            }
        }
    }
    .quote-header {
        *,
        *:before,
        *:after {
            background: transparent !important;
            //color: seashell !important;
            color: white !important;
            box-shadow: inset 0 0 0 1000px #333 !important;
            text-shadow: none !important;
        }
    }

    .quote-box-clean {
        padding: 0px 0px !important;
        margin-left: 15px;
        margin-right: 15px;
        //padding: 10px 20px;
    }

    .account-project-workOrder-printOfficeCopy .anonymousbarWrapper{
        display: none !important;
        width:0 !important;
        height:0 !important;
        border:none !important;
        background: red !important;
    }
    .account-project-workOrder-printOfficeCopy iframe{
        display: none !important;
        width:0 !important;
        height:0 !important;
        border:none !important;
        background: red !important;
    }
    ::-webkit-scrollbar {
        display: none;
    }
}
