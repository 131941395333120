.cmn-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.cmn-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}

input.cmn-toggle-round + label {
  padding: 2px;
  width: 60px;
  height: 30px;
  background-color: #dddddd;
  border-radius: 60px;
}
input.cmn-toggle-round + label:before,
input.cmn-toggle-round + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}
input.cmn-toggle-round + label:before {
  right: 1px;
  background-color: #f1f1f1;
  border-radius: 30px;
  transition: background 0.4s;
}
input.cmn-toggle-round + label:after {
  width: 29px;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: margin 0.4s;
}
input.cmn-toggle-round:checked + label:before {
  background-color: $blue-bright;
}
input.cmn-toggle-round:checked + label:after {
  margin-left: 30px;
}

toggle-switch label {
  font-weight: normal;
  padding: 0 5px;
  // text-transform: uppercase;
  // color: #888;
  font-size: 13px;
  &:hover {
    cursor: pointer;
  }
  &.active {
    color: $blue-bright;
  }
}

toggle-switch {
  display: block;
  margin-top: 3px;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
