.main-content-header {
  border-bottom: $border-light;
  background: #fff;
  .header-inner {
    display: flex;
    height: 100%;
  }
  .btn {
    height: 100%;
    width: 60px;
    @include MQ(S) {
      width: 90px
    }
    @include MQ(Sneg) {
      padding-right: 20px;
    }
  }
  .menu-toggle {
    background: #f0f0f0;
    // border-top-right-radius: 4px;
    // border-bottom-right-radius: 4px;
    &:hover, &:focus {
      background: #ccc;
      outline: none;
    }
  }
  .logout {
    margin-left: auto;
    background: #f0f0f0;
    padding-top: 15px;
    padding-right: 20px;
    padding-left: 15px;
    &:hover {
      background: #ccc;
    }
    @include MQ(Sneg) {
      display: none;
    }
  }
}


