tr.primary-contact {
  .set-contact span{
    display: none;
  }
  .set-contact {
    background: #67c951;
    color: #fff;
    border-radius: 4px;
    padding: 0 10px;
  }
}

.contact:not(.details) {
  .main-inner {
    padding: 10px 30px;
  }
}

.contact-search-wrapper {
  width: 500px;
  padding: 20px;
  background: $light-gray;
  position: relative;
  margin-top: 10px;
  &:before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 15px 10px 15px;
    border-color: transparent transparent $light-gray transparent;
    top: -10px;
    left: 235px;
  }
  .associations {
    text-transform: uppercase;
    font-size: 11px;
    color: #999;
    padding-left: 8px;
  }
  .close {
    position: absolute;
    top: -9px;
    right: -5px;
    color: #333;
    opacity: 1;
  }
  .form-control {
    &:focus {
      outline: none;
      border-color: #aaa;
      box-shadow: none;
    }
  }
  .dropdown-menu {
    width: 100%;
    border-radius: 0;
    padding: 0;
    margin: 0;
    border: $border-light;
    @include MQ(XS) {
      width: calc(100% - 40px);
    }
    > li {
      > a {
        padding: 15px 20px;
        @include truncate;
        strong {
          font-weight: normal;
        }
      }
      &:not(:last-child) {
        a {
          border-bottom: $border-light;
        }
      }
    }
  }
  .dropdown-menu > .active > a,
  .dropdown-menu > .active > a:hover,
  .dropdown-menu > .active > a:focus {
    background-color: #f7f9fa;
    color: $text-color;
  }
}
