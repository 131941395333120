.angular-ui-tree-empty {
  border: 1px dashed #bbbbbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-image: -webkit-linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff), -webkit-linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff);
  background-image: -moz-linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff), -moz-linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff);
  background-image: linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff), linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff);
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
  // display: none;
}

.angular-ui-tree-nodes {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none
}

.angular-ui-tree-nodes .angular-ui-tree-nodes {
  padding-left: 20px
}

.angular-ui-tree-node, .angular-ui-tree-placeholder {
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  line-height: 20px
}

.angular-ui-tree-hidden {
  // display: none
}

.angular-ui-tree-placeholder {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  font-size: 30px;
}

.angular-ui-tree-handle {
  cursor: move;
  text-decoration: none;
  font-weight: 700;
  box-sizing: border-box;
  min-height: 20px;
  line-height: 20px
}

.angular-ui-tree-drag {
  position: absolute;
  pointer-events: none;
  z-index: 999;
  opacity: .8
}