.main-content {
  margin-top: 50px;
  height: 100%;
}

.main-content-header {
  background: #fff;
  z-index: 20;
}

.main-inner {
  // padding: 20px 30px;
  @include MQ(Sneg) {
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
  }
}

.incomplete_products,
.incomplete_wo {
  background: $green;
  margin-left: 4px;
  margin-top: -2px;
}

.section-header {
  border-bottom: $border-light;
  padding: 10px 15px 0 15px;
  background: #fff;
  position: relative;
  @include MQ(Sneg) {
    // background-image: linear-gradient(-208deg, #0278FF 0%, #00B7FF 100%);
    // background: $blue;
  }
  @include MQ(S) {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 10;
    transition: left 300ms ease;
    padding: 15px 30px 0 30px;
  }
  .new-account {
    @include MQ(Sneg) {
      font-size: 14px;
      position: relative;
      top: -5px;
      .fa {
        margin-right: 0;
        font-size: 20px;
      }
    }
  }
  .sidebar-open & {
    @include MQ(S) {
      left: 300px;
    }
  }
  .notes-toggle {
    position: absolute;
    top: 10px;
    right: 5px;
    z-index: 10;
    @include MQ(S) {
      bottom: 0;
      top: auto;
    }
  }
}

.notes-toggle {
  @include MQ(Sneg) {
    .btn {
      background: #f0f0f0;
      border-radius: 4px;
      padding: 8px 10px 8px 12px;
    }
    i {
      font-size: 24px;
    }
    .notes-open & {
      .btn {
        background: #333;
        color: #fff;
      }
    }
  }
}

.notes-toggle-desktop {
  display: none;
  @include MQ(S) {
    display: block;
  }
}

.notes-toggle-mobile {
  height: 100%;
  @include MQ(S) {
    display: none;
  }
  .btn {
    height: 100%;
    width: 60px;
    border-radius: 0;
    padding-top: 11px;
    i {
      font-size: 20px;
    }
  }
}

.section-content {
  padding: 5px 15px 20px 15px;
  @include MQ(S) {
    padding-top: 150px;
    padding-left: 30px;
    padding-right: 30px;
  }
  @include MQ(XL) {
    padding-top: 145px;
  }
}

/*
@styleguide

# Main Action links

<div class="main-actions"
  <ul>
    <li><a href="#" class="active">Recent</a></li>
    <li><a href="#">Permanent</a></li>
    <li><a href="#">One-off</a></li>
    <li><a href="#">Potential</a></li>
    <li><a href="#">Cancelled</a></li>
    <li><a href="#">All</a></li>
  </ul>
</div>

    <div class="main-actions"
      <ul>
        <li><a href="#" class="active">Recent</a></li>
        <li><a href="#">Permanent</a></li>
        <li><a href="#">One-off</a></li>
        <li><a href="#">Potential</a></li>
        <li><a href="#">Cancelled</a></li>
        <li><a href="#">All</a></li>
      </ul>
    </div>

*/


.main-actions {
  @include MQ(Mneg) {
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    position: relative;
    // &:after {
    //   content: "";
    //   position: absolute;
    //   height: 30px;
    //   width: 30px;
    //   top: 0;
    //   right: 0;
    //   background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    // }
  }
  > ul {
    padding: 0;
    margin: 0;
    list-style: none;
    > li {
      display: inline-block;
      margin: 0 3px;
      &:first-child {
        margin-left: 0;
      }
    }
    a {
      text-transform: uppercase;
      display: block;
      border-bottom: 2px solid transparent;
      color: #888;
      font-size: 12px;
      padding: 3px 1px;
      @include MQ(S) {
        border-bottom: 3px solid transparent;
        padding: 2px 15px 5px 15px;
        font-weight: 700;
        font-size: 14px;
      }
      &.active, &:hover, &:focus {
        border-bottom-color: $green;
        text-decoration: none;
        color: #333;
      }
    }
  }


  .dropdown-menu {
    margin: 0;
    a {
      border-bottom: none;
    }
  }
}

// Breadcrumbs

.breadcrumbs {
  display: flex;
  @include MQ(Sneg) {
    text-overflow:ellipsis;
    white-space:nowrap;
    overflow:hidden;
    width: 100%;
    padding-bottom: 10px;
  }
  @include MQ(S) {
    min-height: 62px;
  }
  h1 {
    display: inline-block;
  }
}

.breadcrumb-item {
  display: inline-block;
  position: relative;
  &:not(:last-child) {
    padding-right: 14px;
    @include MQ(S) {
      padding-right: 30px;
    }
    &:before {
      content: "\f054";
      font-family: FontAwesome;
      position: absolute;
      right: 6px;
      // left: 0;
      top: 5px;
      font-size: 13px;
      color: #999;
      @include MQ(S) {
        // right: 6px;
        top: 5px;
        font-size: 20px;
      }
    }
  }
  a {
    &:hover {
      color: $blue;
      text-decoration: none;
    }
  }
}
