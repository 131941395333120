.item-modal {
  .nav {
    font-size: 20px;
    li {
      float: left;
      width: 50%;
    }
    a {
      background: #ddd;
      border-radius: 0 !important;
      border-left: none !important;
      border-right: none !important;
      // background: linear-gradient(to right, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0.05) 5%);
      box-shadow: inset 0px -2px 15px 0px rgba(0,0,0,0.2);
    }
    .active {
      a {
        background: #fff;
        box-shadow: none;
      }
    }
    li:first-child {
      a {
        border-top-left-radius: 4px !important;
      }
    }
    li:last-child {
      a {
        border-top-right-radius: 4px !important;
      }
    }
  }
  .tab-content {
    padding: 30px 40px;
    .btn {
      &:active {
        top: 0;
      }
    }
  }
}

.item-modal-footer {
  padding-bottom: 20px;
}