/*
@styleguide

# Buttons

Use the button classes on an `<a>`, `<button>`, `<input>` elements.

<button class="btn btn-primary">Primary Button</button>
`<button class="btn btn-primary">Button</button>`
<button class="btn btn-secondary">Secondary Button</button>
`<button class="btn btn-secondary">Button</button>`
<button class="btn btn-info">Info Button</button>
`<button class="btn btn-info">Button</button>`
<button class="btn btn-info btn-lg"><i class="fa fa-plus-circle"></i> Info Button</button>
`<button class="btn btn-info btn-lg"><i class="fa fa-plus-circle"></i> Button</button>`
<button class="btn btn-main">Main Button</button>
`<button class="btn btn-main">Button</button>`
<button class="btn btn-simple">Simple Button</button>
`<button class="btn btn-simple">Button</button>`
<button class="btn btn-blue btn-simple">Blue Button</button>
`<button class="btn btn-blue btn-simple">Button</button>`
<button class="btn btn-action">Button Action</button>
`<button class="btn btn-action">Button</button>`

*/

.btn {
  &:active, &:focus {
    outline: none !important;
  }
  &:active {
    position: relative;
    top: 1px;
    box-shadow: none;
  }
}

.btn-zero {
  padding: 0;
}

.btn-action {
  text-transform: uppercase;
  font-size: 12px;
  color: $blue-bright;
  background: none;
  border: none;
  border-radius: 0;
}


.btn-simple {
  background: none;
  border: none;
  border-radius: 0;
}

.btn-blue {
  color: $blue-bright;
}

.btn-dark {
  background: #333;
  color: #fff;
}

.btn-off {
  background: none;
  color: $blue-bright;
}
.btn-on {
  background: $blue-bright;
  color: #fff;
  &:hover {
    background: darken($blue-bright, 10%);
    color: #fff;
  }
}

.btn-main {
  background: $blue-bright;
  color: #fff;
  &:hover {
    background: darken($blue-bright, 10%);
    color: #fff;
  }
}

.btn-secondary {
  color: $blue;
  background: #f0f0f0;
}

.btn-info {
  text-transform: uppercase;
  padding: 5px 10px 5px 5px;
  .fa {
    font-size: 24px;
    position: relative;
    top: 3px;
    margin-right: 2px;
  }
}

.btn-pill {
  background: transparent;
  padding: 2px 10px;
  border-radius: 15px;
  &:hover, &:focus {
    background: rgba(0,0,0,0.3);
  }
}

.btn-lg {
  // font-size: 17px;
  @include MQ(S) {
    font-size: 17px;
  }
}

.btn[disabled] {
  background: #bbb;
}

.btn-icon {
  background: none;
  border: none;
  padding: 3px;
}

.form-actions {
  display: flex;
  border: 1px solid #ddd;
  padding: 10px 15px;
  margin-top: 15px;
  border-radius: 2px;
  align-items: center;
  .button-label {
    margin-right: 5px;
  }
  @include MQ(S) {
    margin-bottom: 15px;
  }
  @include MQ(Sneg) {
    flex-wrap: wrap;
  }
  .btn {
    padding: 10px 15px;
    font-size: 16px;
    @include MQ(S) {
      padding: 10px 30px;
    }
    @include MQ(Sneg) {
      margin-bottom: 5px;
      width: 100%;
    }
    // text-transform: uppercase;
  }
  .btn-info {
    padding: 10px;
    @include MQ(Sneg) {
      padding: 0 5px;
    }
    &:hover {
      color: darken($green, 10%);
    }
    &[disabled] {
      color: #bbb;
      background: transparent;
    }
  }
  .btn-push {
    @include MQ(S) {
      margin-left: auto;
    }
  }
  .btn-ready {
    background: $green;
    &[disabled] {
      background: #ccc;
      i {
        opacity: 0.5;
      }
    }
  }
  .btn-delete {
    color: #777;
    background: transparent;
  }
  .btn-action {
    font-size: 13px;
    padding: 0 5px;
    margin-top: 2px;
  }
  .add-to {
    @include MQ(S) {
      margin-left: 15px;
    }
  }
}

.form-action-group {
  display: flex;
  align-items: center;
  @include MQ(Sneg) {
    border-bottom: 1px solid #ddd;
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  &.add-to {
    @include MQ(Sneg) {
      flex-wrap: wrap;
      .button-label {
        width: 100%;
        text-align: left;
      }
    }
  }
}

.collapse-toggle {
  color: #ccc;
  font-size: 12px;
  position: relative;
  top: -2px;
  transition: transform 300ms  cubic-bezier(0.165, 0.840, 0.475, 1.175);
  .collapsed & {
    transform: rotate(-90deg);
  }
}

.btn-picker {
  border-radius: 50%;
  box-shadow: 1px 2px 2px rgba(0,0,0,0.2);
  background: #fff;
  border: 1px solid #ccc;
  width: 40px;
  height: 40px;
  font-size: 16px;
  padding: 0;
  text-align: center;
  line-height: 33px;
  font-weight: bold;
}

.btn-ord {
  background: $blue-bright;
  color: #fff;
  &:hover, &:focus {
    background: darken($blue-bright, 10%);
    color: #fff;
  }
}

.btn-res,
.btn-stg {
  background: $green;
  color: #fff;
  &:hover, &:focus {
    background: darken($green, 10%);
    color: #fff;
  }
}

.btn-link-blue {
  color: $blue-bright;
}

.btn-outline {
  background-color: transparent;
  border-color: #ccc;
}
