.section-has-notes .section-bottom {
  .section-content {
    // overflow: hidden;
    transition: margin-right 300ms $ease;
    @include MQ(XL) {
      .notes-open & {
        margin-right: 400px;
      }
    }
  }
  .section-notes {
    border-left: $border-light;
    background: #f5f5f5;
    width: 100%;
    position: fixed;
    top: 50px;
    right: 0;
    bottom: 0;
    transform: translate3d(100%,0,0);
    transition: transform 300ms $ease;
    padding: 10px 15px 10px 15px;
    //z-index: 10;
    @include MQ(S) {
      padding: 20px 30px 30px 30px;
      transform: translate3d(400px,0,0);
    }
    @include MQ(S) {
      width: 400px;
      top: 157px
    }
    @include MQ(XL) {

    }
  }
  .notes-open & {
    .section-notes {
      transform: translate3d(0,0,0);
    }
  }
}

.section-notes {
  .form-control {
    border: none;
    border-radius: 0;
    font-style: italic;
    font-size: 18px;
    //padding: 20px 20px 12px 5px;
    padding: 0;
    background: none;
    border-bottom: 2px solid transparent;
    box-shadow: none;
    height: 70%;
    resize: none;
    @include MQ(S) {
      height: 80%
    }
    &:focus {
      // border-bottom-color: #ccc;
      outline: none;
      box-shadow: none;
      border: none;
    }
  }
}

