.edit-dropdown {
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  &.open {
    background: #333;
    color: #fff;
    .dropdown-menu {
      opacity: 1;
      max-height: 500px;
      transition: max-height 1000ms ease-out;
    }
  }
  .dropdown-toggle {
    padding: 3px 12px;
  }
  .dropdown-menu {
    left: auto;
    right: 0;
    min-width: 0;
    padding: 0;
    margin: 0;
    background: #333;
    border: none;
    border-radius: 4px;
    border-top-right-radius: 0;
    overflow: hidden;
    transition: max-height 300ms ease-out;
    opacity: 0;
    display: block;
    max-height: 0;
    > li {
      > a {
        padding: 8px;
        font-size: 11px;
        color: #fff;
        text-transform: uppercase;
        .fa {
          color: #888;
        }
        &:hover {
          background: #222;
        }
      }
      &:not(:last-child) {
        a {
          border-bottom: 1px solid #444;
        }
      }
    }
  }
}