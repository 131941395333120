.daterangepicker {
  background: #f9f9f9;
  .calendar-date {
    padding: 0;
  }
  thead th {
    border-radius: 0;
    border:1px solid #ddd;
    border-bottom-color: #ddd !important;
    background: #eee;
    color: #666;
  }
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background: $blue-bright;
}

.daterangepicker.opensright .ranges {
  float: none !important;
  width: 100% !important;
  text-align: center;
}

.daterangepicker_start_input,
.daterangepicker_end_input {
  display: none;
}

.applyBtn {
  background: $blue-bright;
  border: 1px solid darken($blue-bright, 10%);
  &:hover {
    background: darken($blue-bright, 10%);
    border-color: darken($blue-bright, 15%);
  }
}

.cancelBtn {
  color: $blue-bright;
  border: 1px solid #ddd;
}

.applyBtn,
.cancelBtn {
  padding: 6px 12px;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
}

