// Media Query Mixin

@mixin MQ($canvas){
  @if $canvas == XS{
     @media only screen and (min-width: $XS) { @content; }
  }
  @if $canvas == XSneg{
     @media only screen and (max-width: $XS - 1) { @content; }
  }
  @else if $canvas == S{
     @media only screen and (min-width: $S) { @content; }
  }
  @else if $canvas == Sneg{
    @media only screen and (max-width: $S - 1) { @content; }
  }
  @else if $canvas == M{
     @media only screen and (min-width: $M) { @content; }
  }
  @else if $canvas == Mneg{
     @media only screen and (max-width: $M - 1) { @content; }
  }
  @else if $canvas == L{
     @media only screen and (min-width: $L) { @content; }
  }
  @else if $canvas == Lneg{
   @media only screen and (max-width: $L - 1) { @content; }
}
  @else if $canvas == XL{
     @media only screen and (min-width: $XL) { @content; }
  }
  @else if $canvas == XLneg{
     @media only screen and (max-width: $XL - 1) { @content; }
  }
  @else if $canvas == S-M{
     @media only screen and (min-width: $S) and (max-width: $M - 1) { @content; }
  }
  @else if $canvas == M-L{
     @media only screen and (min-width: $M) and (max-width: $L - 1) { @content; }
  }
  @else if $canvas == L-XL{
     @media only screen and (min-width: $L) and (max-width: $XL - 1) { @content; }
  }
}


@mixin truncate {
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden;
}

