spinner {
  position: absolute;
  right: 15px;
}

spinner .hide {
  visibility: hidden;
}
.load-indicator {
  right: 0px;
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.load-indicator > div {
  background-color: rgb(142, 151, 156);
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.load-indicator .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.load-indicator .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.load-indicator .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.load-indicator .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
       transform: scaleY(1.0);
       -webkit-transform: scaleY(1.0);
     }
}